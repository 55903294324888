export default function() {
  // add click event listeners on all the buttons
  const fontSizeButtons = Array.from(
    document.querySelectorAll(".font-size-changer button")
  );
  fontSizeButtons.forEach(fontSizeButton => {
    fontSizeButton.addEventListener("click", buttonClicked => {
      setFontSize(fontSizeButton.dataset.size);
    });
  });

  // set the font size from localStorage so it maintains size between pages and refresh
  const storedFontSize = localStorage.getItem("fontSize");
  if (storedFontSize) {
    setFontSize(storedFontSize);
  }

  function setFontSize(fontSize) {
    const containerToChange = document.querySelector("body");
    const ACTIVE_CLASS_NAME = "active";
    const fontSizes = {
      normal: "1em",
      larger: "1.1em",
      largest: "1.2em"
    };

    fontSizeButtons.forEach(fsb => fsb.classList.remove(ACTIVE_CLASS_NAME));
    document
      .querySelector(`.font-size-changer .${fontSize}`)
      .classList.add(ACTIVE_CLASS_NAME);

    containerToChange.style.fontSize = fontSizes[fontSize];
    localStorage.setItem("fontSize", fontSize);
  }
}

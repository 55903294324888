export default function() {
  const fileInputs = Array.from(
    document.querySelectorAll('input[type="file"]')
  );

  fileInputs.forEach(fileInput => {
    fileInput.addEventListener("change", e => {
      const filenameEl = fileInput.parentNode.querySelector(".filename");
      console.log(fileInput.files);
      const file = fileInput.files[0];
      if (file) {
        filenameEl.innerHTML = file.name;
      }
    });
  });
}

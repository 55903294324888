import Chart from "chart.js"

export const initBarGraph = function(labels, data) {
  Chart.defaults.global.legend.display = false;
  Chart.defaults.global.animation = {
    duration: 1000,
    easing: 'easeOutQuart'
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        backgroundColor: "#36982E",
        borderColor: "#36982E",
        data: data
      }
    ]
  }

  const chartOptions = {
    // The type of chart we want to create
    type: "bar",

    // The data for our dataset
    data: chartData,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: {
            display:false
          },
          ticks: {
            maxRotation: 90,
            minRotation: 90
          }
        }],
        yAxes: [{
          ticks: {
            maxTicksLimit: 5,
            precision: 0
          },
          gridLines: {
            display:false
          },
          scaleLabel: {
            display: true,
            labelString: '# OF CODES SENT'
          }
        }]
      }
    }
  }

  // this really does need to be placed in a windows.onload wrapper of some kind or the chart's initial
  // width will be larger than the screen
  window.onload = function() {
    const usageElement = document.getElementById("usage")
    if (!usageElement) {
      return
    }

    const ctx = usageElement.getContext("2d");
    const chart = new Chart(ctx, chartOptions);
  }
}

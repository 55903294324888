import ScrollReveal from "scrollreveal"

export default function() {
  window.sr = ScrollReveal({
    reset: false,
    scale: 1
  });

  const slideLeft = {
    distance: '25%',
    opacity: 0,
    origin: 'left'
  };

  const slideRight = {
    distance: '25%',
    opacity: 0,
    origin: 'right'
  };

  const slideOpactiy = {
    opacity: 0,
    distance: '0px',
    duration: 500,
    interval: 100
  };

  sr.reveal(".srl", slideLeft);
  sr.reveal(".srr", slideRight);
  sr.reveal(".sro", slideOpactiy);
}

export const isEdge = function() {
  return /Edge\/\d./i.test(navigator.userAgent);
};

export const isMobileSafari = function() {
  let isMobileMac =
    window.navigator.userAgent.match(/iPad/i) ||
    window.navigator.userAgent.match(/iPhone/i);
  return isMobileMac;
};

export const isFirefox = function() {
  return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
};

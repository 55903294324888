import "babel-polyfill";
import $ from "jquery";
import validate from "jquery-validation";
import objectFitPoly from "objectFitPolyfill";
import "nodep-date-input-polyfill";
import formAnimate from "./form-animate.js";
import formValidation from "./form-validation";
import fontSizeChanger from "./freedom-pass/font-size-changer";
import fileUploadControl from "./freedom-pass/file-upload-control";
import scrollReveal from "./freedom-pass/scroll-reveal";
import rellax from "./rellax";
import { initBarGraph } from "./bar-graph";

import { isFirefox } from "./determine-browser";

window.$ = window.jQuery = $;

formAnimate();
formValidation();
fontSizeChanger();
rellax();
scrollReveal();
fileUploadControl();

if (window.usage_labels && window.usage_values) {
  initBarGraph(window.usage_labels, window.usage_values);
}

if (isFirefox()) {
  document.body.classList.add("firefox");
}

import Rellax from "rellax";

export default function() {
  let relEl = document.getElementsByClassName("rellax");
  if (relEl.length > 0) {
    const rellax = new Rellax(".rellax", {
      speed: -2
    });
  }
}

export default function() {
  jQuery(document).ready(function() {
    //ADA compliant plugin form validation plugin
    var $contactForm = $(".validate-form");
    $contactForm.validate({
      errorPlacement: function(error, element) {
        error.appendTo("label[for=" + $(element).attr("id") + "]");
      },
      errorElement: "span",
      highlight: function(element, errorClass, validClass) {
        $(element)
          .addClass(errorClass)
          .removeClass(validClass);
        $("label[for=" + $(element).attr("id") + "]")
          .addClass(errorClass)
          .removeClass(validClass);
      },
      unhighlight: function(element, errorClass, validClass) {
        $(element)
          .removeClass(errorClass)
          .addClass(validClass);
        $("label[for=" + $(element).attr("id") + "]")
          .removeClass(errorClass)
          .addClass(validClass);
      },
      submitHandler: function(form) {
        form.submit();
        $contactForm.find("button").prop("disabled", true);
        // let currentLocation = window.location.href;
        // let lastChar = currentLocation.substr(-1);
        // if (lastChar !== "/") {
        //   var successLocation = currentLocation + "/success";
        // } else {
        //   var successLocation = currentLocation + "success";
        // }
        // window.location.href = successLocation;
      }
    });
    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    // on page load check if it's a contact page, and then check for errors from pardot post action redirect
    //name="First_Name"
    //http://127.0.0.1:4000/
    let urlPath = window.location.pathname;
    let currentLocation = window.location.href;
    let fromLocation = document.referrer;
    let errorMessage = getParameterByName("errorMessage");
    let pardotRedirect = currentLocation === fromLocation; // will change to getParam
    let isContact =
      urlPath.indexOf("contact") != -1 && urlPath.indexOf("success") == -1;
    if (isContact && pardotRedirect) {
      // change to simple if success/else error block when query is created
      let isError = urlPath.indexOf("error") != -1;
      let lastChar = currentLocation.substr(-1);
      if (!errorMessage) {
        if (lastChar !== "/") {
          var successLocation = currentLocation + "/success";
        } else {
          var successLocation = currentLocation + "success";
        }
        $contactForm.find("button").prop("disabled", true);
        window.location.href = successLocation;
      } else {
        $contactForm.prepend(errorMessage);
      }
    }
  });
}

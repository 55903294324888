export default function() {
  // expose this to the global window context so our other app, eg: Vue apps, can call this when forms are mounted
  window.formAnimate = function() {
    const FOCUS_CLASS_NAME = "focused";

    const formGroups = document.getElementsByClassName("form-group");

    for (const formGroup of formGroups) {
      const label = formGroup.getElementsByTagName("label")[0];
      if (label === undefined) {
        continue;
      }

      const input = formGroup.getElementsByTagName("input")[0];
      if (input !== undefined) {
        setupFocus(input);
        setupBlur(input);
      }

      const textarea = formGroup.getElementsByTagName("textarea")[0];
      if (textarea !== undefined) {
        setupFocus(textarea);
        setupBlur(textarea);
      }

      const select = formGroup.getElementsByTagName("select")[0];
      if (select !== undefined) {
        setupFocus(select);
        select.addEventListener("blur", e => {
          const placeholder = e.target.getElementsByTagName("option")[0].value;
          if (e.target.value == placeholder) {
            label.classList.remove(FOCUS_CLASS_NAME);
          }
        });

        const placeholder = select.getElementsByTagName("option")[0].value;
        if (select.value != placeholder) {
          label.classList.add(FOCUS_CLASS_NAME);
        }
      }

      function setupFocus(element) {
        if (element === undefined) {
          return;
        }

        element.addEventListener("focus", () => {
          label.classList.add(FOCUS_CLASS_NAME);
        });

        if (element.value.length > 0) {
          label.classList.add(FOCUS_CLASS_NAME);
        }
      }

      function setupBlur(element) {
        if (element === undefined) {
          return;
        }

        element.addEventListener("blur", e => {
          if (e.target.value.length < 1) {
            label.classList.remove(FOCUS_CLASS_NAME);
          }
        });
      }
    }
  };

  window.formAnimate();
}
